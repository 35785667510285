import { Button as AntButton } from 'antd';
import styled, { css } from 'styled-components';

const Button = styled(AntButton)`
  background: var(--blue);
  border-radius: var(--border-radius-large);
  color: var(--white);
  padding: 9px 20px;
  height: 42px;
  font-weight: 500;
  &:hover,
  &:active,
  &:focus {
    background: var(--blue);
    box-shadow: 0px 1px 7px rgba(0, 146, 239, 0.35);
    color: var(--white);
  }
  &:disabled,
  &:disabled:hover {
    background: var(--blue);
    opacity: 0.5;
    color: var(--white);
  }
  ${(props) =>
    props.itemProp &&
    css`
      color: var(--black);
      background: linear-gradient(
        180deg,
        var(--white) 0%,
        var(--lighter-gray) 100%
      );
      border: 1px solid #c4cdd5;
      font-weight: normal;
      &:hover,
      &:focus {
        color: var(--black);
        background: linear-gradient(
          180deg,
          var(--white) 0%,
          var(--lighter-gray) 100%
        );
        box-shadow: 0px 2px 6px rgba(43, 93, 189, 0.25);
        border: 1px solid #c4cdd5;
      }

      &:disabled,
      &:disabled:hover {
        color: var(--gray);
        background: linear-gradient(
          180deg,
          var(--white) 0%,
          var(--lighter-gray) 100%
        );
      }
    `}
`;

export { Button };
