import * as CryptoJS from 'crypto-js';

// Write Javascript code!
const encryptSecretKey: any = process.env.REACT_APP_SECRET_KEY; //adding secret key
//Data Encryption Function
export function encryptData(param: any) {
  const keySize = 256;
  const salt = CryptoJS.lib.WordArray.random(16);
  const key = CryptoJS.PBKDF2(encryptSecretKey, salt, {
    keySize: keySize / 32,
    iterations: 100,
  });

  const iv = CryptoJS.lib.WordArray.random(128 / 8);

  const encrypted = CryptoJS.AES.encrypt(param, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  const result = CryptoJS.enc.Base64.stringify(
    salt.concat(iv).concat(encrypted.ciphertext)
  );
  return result;
}
