import React, { useState } from 'react';
import { Modal } from 'antd';
import { isEmpty } from 'lodash';

import Select from 'components/common/Select';
import { Button } from 'components/common/Button';

interface MultiStoreProps {
  userName: any;
  stores: Array<any>;
  storeHandler: (params: any) => void;
}

const MultiStore = ({
  userName,
  stores = [],
  storeHandler,
}: MultiStoreProps) => {
  const [selectedStore, setSelectedStore] = useState<object>({});

  const onSelectionChange = (value: any) => {
    const selectedStore = stores?.find((el: any) => el.storeId === value);
    setSelectedStore(selectedStore);
  };

  const storeOptions = stores.map((el: any, index: number) => {
    return {
      key: index,
      value: el.storeId,
      label: `${el.storeName} - ${el.storeId}`,
    };
  });

  return (
    <Modal
      width="600px"
      style={{ top: '25vh' }}
      open={true}
      closable={false}
      footer={[
        <Button
          disabled={isEmpty(selectedStore)}
          onClick={() => storeHandler(selectedStore)}
          key="multi-store-selection"
        >
          Submit
        </Button>,
      ]}
    >
      <h2>Welcome {userName}</h2>
      <h2>Please select the store you wish to work from.</h2>
      <Select onChange={onSelectionChange} options={storeOptions} />
    </Modal>
  );
};

export default MultiStore;
